<template>
  <div id = "app " >
   <notifications position="bottom right"  :width=600 class="test" />
    <div class="sidebar"  v-if="loggedIn" >
        <div class="side1 d-flex flex-column " style="height: 7%; padding: 6px 14px 6px 14px;">
          <div class="logo-details  w-100 h-100">
            <div class="logo_name" style="color : rgba(204, 2, 2, 0.383)  ; font-weight : bolder; font-family:'Times new roman'">
              Menu J
         

            </div>
            <i class='bx bx-menu' id="btn" @click="close"></i>
        </div>
        </div>
 
        <ul class="side2 nav-list d-flex align-items-start flex-column" style=" padding: 6px 14px 6px 14px;" >
         <!-- <li>
              <i class='bx bx-search' ></i>
             <input type="text" placeholder="Search...">
             <span class="tooltip">Search</span>
          </li>-->
          <li v-if="permissions[0] == 2" class="">
            <a  @click="changeMenuColor('dash')" v-bind:class="menuItem == 'dash' ?  'bg-lighter' :''" >
              <keep-alive>
                <router-link to="/d">
              <i class='bx bx-grid-alt' ></i>
              <span class="links_name">Dashboard</span>
              </router-link>
              </keep-alive>
            </a>
             <span class="tooltip">Dashboard</span>
          </li>

          <li v-if="permissions[1] == 2" class="sidebar-dropdown" id="test">
            <a @click="sidebarDropdown">
              <i class='bx bxs-widget'></i>
             <span class="links_name">Gestion Colis</span>
           </a>
           <span class="tooltip">Gestion Colis</span>
         </li>
         <div class="sidebar-submenu pl-2 w-100">
              <ul>
                <li v-if="permissions[1] == 2">
            <a @click="changeMenuColor('colis')" v-bind:class="menuItem == 'colis' ?  'bg-lighter' :''" >
              <router-link to="/shipment">
              <i class='bx bx-package'  ></i>
             <span class="links_name"> Colis</span>
              </router-link>
           </a>
           <span class="tooltip"> Colis</span>
         </li>
                <li v-if="permissions[3] == 2">
           <a @click="changeMenuColor('sel')"  v-bind:class="menuItem == 'sel' ?  'bg-lighter' :''"  >
             <router-link to="/seller">
             <i class='bx bxs-cart'></i>
             <span class="links_name">Vendeurs</span>
             </router-link>
           </a>
           <span class="tooltip">Vendeurs</span>
         </li>
          <li v-if="permissions[5] == 2" >
           <a @click="changeMenuColor('relay')" v-bind:class="menuItem == 'relay' ?  'bg-lighter' :''">
             <router-link to="/relay">
             <i class='bx bxs-map' ></i>
             <span class="links_name">Point de relais</span>
             </router-link>
           </a>
           <span class="tooltip">Point de relais</span>
         </li>
         <li v-if="permissions[5] == 2" >
           <a @click="changeMenuColor('cl')" v-bind:class="menuItem == 'cl' ?  'bg-lighter' :''">
             <router-link to="/customer">
             <i class='bx bx-group' ></i>
             <span class="links_name">Clients</span>
             </router-link>
           </a>
           <span class="tooltip">Clients</span>
         </li>
          <!-- <li v-if="permissions[4] == 2" class="sidebar-dropdown w-100">
           <a @click="changeMenuColor('cl')" v-bind:class="menuItem == 'cl' ?  'bg-lighter' :''" >
             <router-link to="/customer">
             <i class='bx bx-group' ></i>
             <span class="links_name">Clients</span>
             </router-link>
           </a>
           <span class="tooltip">Clients</span>
         </li> -->
              </ul>
            </div>

         <li v-if="permissions[6] == 2" >
           <a @click="changeMenuColor('ware')" v-bind:class="menuItem == 'ware' ?  'bg-lighter' :''" >
             <router-link to="/warehouse">
             <i class='bx bx-store-alt' ></i>
             <span class="links_name">Centres de tri</span>
             </router-link>
           </a>
           <span class="tooltip">Centres de tri</span>
         </li>

         <li v-if="permissions[8] == 2" >
           <a @click="changeMenuColor('ch')"  v-bind:class="menuItem == 'ch' ?  'bg-lighter' :''"  >
             <router-link to="/driver">
            <i class='bx bxs-truck'></i>
             <span class="links_name">Transporteur</span>
             </router-link>
           </a>
           <span class="tooltip">Transporteur</span>
         </li>
         <li v-if="permissions[1] == 2" class="sidebar-dropdown" id="test">
            <a @click="sidebarDropdown">
              <i class='bx bxs-widget'></i>
             <span class="links_name">Package</span>
           </a>
           <span class="tooltip">Package</span>
         </li>
         <div class="sidebar-submenu pl-2 w-100">
              <ul>
         <li v-if="permissions[9] == 2" >
           <a @click="changeMenuColor('pack')" v-bind:class="menuItem == 'pack' ?  'bg-lighter' :''">
             <router-link to="/packages">
            <i class='bx bxs-component' ></i>
             <span class="links_name">Package</span>
             </router-link>
           </a>
           
           <span class="tooltip">Package</span>
         </li>
         <li v-if="permissions[9] == 2" >
           <a @click="changeMenuColor('dr')" v-bind:class="menuItem == 'fr' ?  'bg-lighter' :''">
             <router-link to="/provider">
           <i class='bx bx-bus' ></i>
             <span class="links_name">Fournisseur</span>
             </router-link>
           </a>
           
           <span class="tooltip">Fournisseur</span>
         </li>
              </ul>
         </div>
         <li v-if="permissions[9] == 2">
           <a @click="changeMenuColor('facture')" v-bind:class="menuItem == 'facture' ?  'bg-lighter' :''" >
             <router-link to="/factures">
              <i class='bx bxs-file'></i>
              <span class="links_name">Facture</span>
             </router-link>
           </a>
           <span class="tooltip">Facture</span>
         </li>
          <li v-if="permissions[15] == 2" >
           <a @click="changeMenuColor('fin')" v-bind:class="menuItem == 'fin' ?  'bg-lighter' :''" >
             <router-link to="/finance">
            <i class='bx bx-line-chart' ></i>
             <span class="links_name">Finance</span>
             </router-link>
           </a>
           <span class="tooltip">Finance</span>
         </li>

          <li v-if="permissions[9] == 2">
           <a @click="changeMenuColor('scanner')" v-bind:class="menuItem == 'scanner' ?  'bg-lighter' :''" >
             <router-link to="/c_shipping">
            <i class='bx bx-barcode-reader' ></i>
             <span class="links_name">Scanner</span>
             </router-link>
           </a>

           <span class="tooltip">Scanner</span>
         </li>
          <li v-if="permissions[1] == 2" class="sidebar-dropdown" id="test">
            <a @click="sidebarDropdown">
              <i class='bx bxs-widget'></i>
             <span class="links_name">Administration</span>
           </a>
           <span class="tooltip">Administration</span>
         </li>
         <div class="sidebar-submenu pl-2 w-100">
              <ul>
         <li v-if="permissions[9] == 2" >
           <a @click="changeMenuColor('compte')" v-bind:class="menuItem == 'compte' ?  'bg-lighter' :''">
             <router-link to="/comptes">
            <i class='bx bxs-user-account' ></i>
             <span class="links_name">Comptes</span>
             </router-link>
           </a>
           
           <span class="tooltip">Comptes</span>
         </li>

         <li v-if="permissions[9] == 2" >
           <a @click="changeMenuColor('histo')"  v-bind:class="menuItem == 'histo' ?  'bg-lighter' :''" >
             <router-link to="/access">
            <i class='bx bx-history' ></i>
             <span class="links_name">Historique</span>
             </router-link>
           </a>
           
           <span class="tooltip">Historique</span>
         </li>

              <li v-if="permissions[10] == 2"  >
           <a @click="changeMenuColor('perm')" v-bind:class="menuItem == 'perm' ?  'bg-lighter' :''" >
             <router-link to="/permissions">
             <i class='bx bx-lock-open-alt' ></i>
             <span class="links_name">Permissions</span>
             </router-link>
           </a>
           <span class="tooltip">Permissions</span>
         </li>
              </ul>
         </div>

         <li v-if="permissions[11] == 2" >
           <a @click="changeMenuColor('set')" v-bind:class="menuItem == 'set' ?  'bg-lighter' :''" >
             <router-link to="/settings">
             <i class='bx bx-cog' ></i>
             <span class="links_name">Paramètres</span>
             </router-link>
           </a>
           <span class="tooltip">Paramètres</span>
         </li>
          <li  class="logOut" >
           <a v-on:click="logout()" >
             <i class='bx bx-log-out' ></i>
             <span class="links_name">{{profile}}</span>
           </a>
           <span class="tooltip">Logout</span>
         </li>
        

         

        </ul>
      </div>
      
      
     <div v-if="show" class="bg-white">
    <transition name="model">
     <div class="modal-mask bg-secondary" >
      <div class="modal-wrapper">
       <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content bg-light">
       
         <div class="modal-body">
             <p class="px-3 py-2 text-dark font-weight-bold content-justify">                  
             Vous etes déconnectés !
         </p>   
          <div class="flex-row d-flex float-end mt-4 mb-0">
                       <div class="input-group-prepend " >
                <button type="button" class="btn btn-secondary  px-4 btn-icon-text" v-on:click="ok()">
  <i class="mdi mdi-check-circle btn-icon-prepend"></i>
  OK
  </button>
              </div>
                    </div>  
         
    
         

          
         </div>
        </div>


        <!------------------------>

      
        
       </div>
      </div>
     </div>
    </transition>
   </div> 
   
   
    
<!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
</keep-alive> -->
<router-view v-slot="{ Component }"  v-if="$route.meta.keepAlive">
  <keep-alive :timeout=1 >
    <component :is="Component" />
  </keep-alive> 
</router-view>  

<router-view v-if="!$route.meta.keepAlive"></router-view> 
<!--  <router-view />
 --> 
  </div>
  
  
</template>
<script>
//import {testing} from '../../public/script.js'
import axios from 'axios';
 import Dashboard from '@/views/Dashboard.vue';
/*import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';
import Colis from '@/views/shipment.vue';
import client from '@/views/Client.vue' */
import moment from 'moment';
import $ from 'jquery'


export default {
  components: { /* Dashboard, Footer, Header, Colis, client */},

         data() {
               return {
     // token : null,
      id : null,
      login : null,
      user : null,
     // permissions : [],
      c:"",
      menu: '',
      show : false,
      timeSet:"",
      myInterval:0,
      activity:{}
    };
        },
 /*  updated(){
    console.log("*********updated");
    console.log("login_time", this.logTime)
    console.log("duration ",this.duration)
    //if(this.duration >= 15) this.logout;

  }, */

 computed :{
          profile(){
          //console.log("profile");
          return this.$store.getters.get_profile.type_user;
                  },
          userlogged(){
          return this.$store.getters.get_user;
              },
          loggedIn(){
          //console.log("loggedin",this.$store.getters.get_loggedIn)
          return this.$store.getters.get_loggedIn;
                    },
          permissions(){
          //console.log("perm",this.$store.getters.get_permissions)
          return this.$store.getters.get_permissions.split("");
                       },
          menuItem(){
          //console.log("menu item",this.$store.getters.get_menuItem)
          return this.$store.getters.get_menuItem;
                    },
          logTime(){
          //console.log("login time");
          
          return this.$store.getters.get_loginTime;
                  },
          duration(){
          var login_time = moment(this.logTime); 
          var now_time = moment();
          return now_time.diff(login_time, 'h');
          },
           utilisateur(){
             
          return this.$store.getters.get_userAcc;
         },
 

 },
 //loggedin and utilisateur are porpreties not functions
created(){
  let login_time = moment().format("HH:mm")
  let date2 = moment('13-11-2019').format("HH:mm");


//console.log(this.current_time.diff(this.date2))
  var startTime = moment('12:16:59 am', 'HH:mm');
var endTime = moment('06:12:07 pm', 'HH:mm');

// calculate total duration
var duration = endTime.diff(startTime);
//console.log("duration",duration)

},
 


        mounted() {
          console.log(this.profile)
       this.myInterval = window.setInterval(() => {
   // console.log(this.logTime)
   // console.log("*********", this.testDeconnexion());
    if(this.testDeconnexion() >= 8) this.logout();
  }, 1000) 
  //console.log("user acc",this.utilisateur)
      $(document).on("click",".sidebar-dropdown",function() {
  $(".sidebar-submenu").slideUp(250);
    $(this)
      .next(".sidebar-submenu")
      .slideDown(250);
});


          

      
  
          

    //this.permissions= this.profile.permissions.split("");
    //console.log(this.permissions)

//this.permissions =  this.profile.permissions.split("");

//this.checkUserId();

  },
    methods: {
      close(){
         //document.querySelector(".sidebar").classList.toggle("open");
         let sidebar = document.querySelector(".sidebar");
let closeBtn = document.querySelector("#btn");
let searchBtn = document.querySelector(".bx-search");
    //closeBtn.addEventListener('click', ()=>{ alert("HOVER2") })
  sidebar.classList.toggle("open");
//  menuBtnChange();//calling the function(optional)

this.menuBtnChange(sidebar, closeBtn);
$(".sidebar-submenu").hide('fast');

      },
     menuBtnChange(sidebar, closeBtn) {
 if(sidebar.classList.contains("open")){
   closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
 }else {
   closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
 }
},
      thing(){
        //console.log("*****************")

      },
      changeMenuColor(m){
       this.$store.commit("set_ItemMenu", m);
       this.menu = this.menuItem;

      },
      stop() {
        //console.log("**************", this.profile)
        console.log("stoped")
        window.clearInterval(this.myInterval);
        },
    /* checkUserId(){
     // console.log(localstorage.getItem("user"))
     //console.log(localStorage.getItem("userID"));
     this.id = localStorage.getItem("userID");
     //uqer Data
     axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .get("http://127.0.0.1:8000/api/getData/"+this.id)
     .then(res => {
       this.user = res.data;
       this.login = this.user.login
       console.log(this.login)

     })
     
     
     //
      
    }, */
    ok(){
      this.show = false;
      this.$router.push('/');

    },
    quit(){
    this.logout();
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
    logout(){
      
       this.stop();
       this.addActivity( this.userlogged.id, '/api/logout')
      //this.$router.push('/');
      //  window.location.reload();
      //this.show = true;
      /* localStorage.removeItem('token')
      localStorage.removeItem('userID') */
      
      this.$store.dispatch('performLogout')
      .then( res => {
        console.log(res)
      

      })
      .catch( err => {
        console.log(err)
      }) 
    },
   
     addActivity(id, link){
      this.activity.user_id= id;
      this.activity.action= "logged out";
      this.activity.model = "User"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = id;
            console.log("add activity")

      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log("yes")
          console.log(res.data);
        })
        .catch(error => {
          console.log("no")
            console.log(error)
           
        }) 

      },
      sidebarDropdown1(){
         $(".sidebar-submenu").slideUp(250);
  if (
    $(this)
      .parent()
      .hasClass("active")
  ) {
    alert('yes');
   
  } else {
    alert('no');
    $(this)
      .next(".sidebar-submenu")
      .slideDown(250);
  }

      },
    testDeconnexion(){
      var login_time = moment(this.$store.getters.get_loginTime); 
      var now_time = moment();
      return now_time.diff(login_time, 'h');
    }

  }
       }
</script>
<style scoped lang="scss">
/* Google Font Link */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}

.bg-lighter{
  background: rgba(204, 2, 2, 0.383) !important;	
  
}


.sidebar{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px;
  background: #11101D;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open{
  width: 250px;
  
}
.sidebar .logo-details{
  height: 5%;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon{
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
  margin: 30%;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name{
  opacity: 1;
}
.sidebar .logo-details #btn{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn{
  text-align: right;
}
.sidebar i{
  color: #acabab;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar a:hover i{
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar .nav-list{
  margin-top: 9px;
  height: 91%;
}
.sidebar li{
  position: relative;
  list-style: none;
  width: 100%;
  height: 7%;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #acabab;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar-submenu a{
  height: 40px !important;
  color: #797575 !important;

}
.sidebar{
  margin: 0% !important;
}
.sidebar-submenu i, .sidebar-submenu .links_name{
  color: #797575 !important;
}
.sidebar li:hover .tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip{
  display: none;
}

.sidebar input{
  font-size: 15px;
  color: #acabab;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.sidebar.open input{
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #acabab;
}
.sidebar.open .bx-search:hover{
  background: #1d1b31;
  color: #acabab;
}
.sidebar .bx-search:hover{
  background: rgb(235, 147, 147);
  color: #11101d;
}

.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
 /* // background: #11101D; */
}
.sidebar li a:hover{
  background: none;
  cursor: context-menu;
}
.bg-darkslate{
  background: #11101D;
  
}
 .active{
  background: darkred;
} 
.sidebar li a .links_name{
  color: #acabab;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name{
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i{
  transition: all 0.5s ease;
  color: #343436;
}
.sidebar li i{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile{
  position: fixed;
  height: 4%;
  bottom: 0;
  padding: 10px 14px;
  /* background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden; */
}
.sidebar.open li.profile{
  width: 250px;
}
.sidebar li .profile-details{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job{
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job{
  font-size: 12px;
}
.sidebar .profile #log_out{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }
   .logOut{
     margin-bottom: 0;
     margin-top: auto;
   }
  .sidebar-submenu {
  display: none;
}



</style>



