<template>
  <div classe = ""  >
  
     <!-- template -->
   <div class=""  >
    <div class=""   ></div>
    <div class=" w-100  " style=" height : 100%;" >
        <img src="login/images/k2.jpg" alt="login" class=" w-100 position-fixed" style="height : calc(100vh - 0px);">
 <div class="card border-0 align-items-start" style="background: transparent; height : 10%; width: 5%; margin-left: 2%; margin-top:-1%;">
                  <img src="images/faces/jiblili.png" class="w-100  h-100" alt="logo"/>
                </div>
              <!--   <div class="card border-0 align-items-start" style="background: transparent; ; margin-left: 2%; margin-top:5%;">
                <h1>Welcome back to JIBLILI EXPRESS </h1>
                </div>
 -->
 <div class="row d-flex justify-content-center w-100">
    <div style="white-space: pre-line"  v-if="msg_succes" class="alert alert-success w-75 border border-light px-5 shadow-sm" role="alert">
                             <i class="mdi mdi-check-circle-outline " style="color:darkgreen;"></i>
                 {{msg_succes}} 
                             <i class="mdi mdi-close float-right " type="button" @click="msg_succes = '';" style="color:black;"></i>

                </div>

 </div>
      <div class="row d-flex  w-100  " style="margin-left:  10% ; ">
        <div class="row     d-flex align-content-stretch    w-100"  >
          
         <!--  <div class="card border-0 p-0     col-lg-12" >


          </div> -->


            <div class="col-lg-3 py-5 position-fixed" style="background :rgba(0, 0, 0, 0.1);  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; height : 86%;">
              <div class="text-center mb-">
               
              <h3 style="color: black; font-weight:bolder; margin:20% 0;"><strong >Welcome back ;)</strong></h3>
              <!-- <p class="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p> -->
              </div>
              <div class="d-flex justify-content-center w-100">
              <form @submit.prevent="handlesubmit" class="w-75" >
                <div class="form-group first">
                  <label for="email">Email</label>
                  <input type="email" class="form-control bg-light"  id="email" v-model="email" required>
                </div>
                <div class="form-group last ">
                  <label for="password">Mot de passe</label>
                  <input type="password" class="form-control bg-light"  id="password" v-model="password" required>
                </div>
              
                
                <div class="d-sm-flex align-items-center">
                  <!-- <label class="control control--checkbox mb-3 mb-sm-0"><span class="caption">Remember me</span>
                    <input type="checkbox" checked="checked"/>
                    <div class="control__indicator"></div>
                  </label> -->
                  <!-- <span class="ml-auto"><a href="#"  class="text-secondary  forgot-pass" @click="open">Mot de passe oublié</a></span>  -->
                </div>
                 
                <div class="row d-flex justify-content-center">
                                  <input id="inputLogin" v-show="!isLoading" type="submit" value="Se connecter" class="mt-5 mb-5 w-50   btn btn-block text-white " style="background : #CE0000;" >

                </div>

                        <div class="text-center">
                          <loading v-if="isLoading" :active="true" :width="50" :height="50" color="red"></loading>
                        </div>
                        <div class="row d-flex ">
                           <div style="white-space: pre-line"  v-if="error" class="alert alert-danger border border-white shadow-sm" role="alert">
                             <i class="mdi mdi-close-circle-outline " style="color:darkred;"></i>
                 {{ error }}
                </div>
                        </div>
                

              </form>
              </div>

                <div v-if="myModel" class="">
    <transition name="model">
     <div class="modal-mask ">
      <div class="modal-wrapper">
       <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content bg-white"  >
           <div class="modal-header bg-white p-4" >
                     <h5 class="modal-title w-100 text-center text-secondary  " >Réinitialisation mot de passe</h5>

          <button type="button" class="border-0 bg-transparent"  @click="close()"><i class="mdi mdi-close text-secondary "></i></button>
         </div>
       
         <div class="modal-body w-100 ">
             <p class="px-3 py-2 text-dark font-weight-bold content-justify  my-0">                  
            Il semblerait que vous avez oublié votre mot de passe, Veuillez enter votre adresse email pour vous envoyer un nouveau
         </p>   
         <form @submit.prevent="envoyer">
          <div class="form-group   w-100 px-5 d-flex justify-content-center  ">
          <div class="row w-75  mt-2   m-0">
             <label for="exampleInputUsername2" class="col-lg-2 col-form-label text-secondary">Email</label>
          <div class="col-lg-10 ">
              <input type="email" class="form-control bg-white  shadow-sm py-3" id="inputEmail3" placeholder="Email@email.com" v-model="mail" required>
          </div>
        </div>
          </div>

         <div style="white-space: pre-line"  v-if="error_mail" class="alert border border-white shadow-sm  alert-danger mx-4 mt-0 text-center" role="alert">
             <i class="mdi mdi-close-circle-outline " style="color:darkred;"></i>

                 {{ error_mail }}
                </div>
  
           <div class="d-flex justify-content-end my-3 mr-2">
            <button  type="submit"  class="btn btn-danger py- 1 px-4 " :disabled="sent" >
              <span v-if="!sent">Envoyer</span>
              <span v-else>Envoi en cours ...</span>
             
           </button>

          </div> 
                   </form>

   
         
    
         

          
         </div>
        </div>


        <!------------------------>

      
        
       </div>
      </div>
     </div>
    </transition>
   </div> 


         </div>
        </div>
      </div>
    </div>
                                     <p class=" mr-4 text-secondary " style="font-size : 10px; position:fixed; bottom:0; right:0;">Vecteur créé par macrovector - fr.freepik.com </p>


    
  </div>
 

  <!------------------->
  </div>

</template>
<script>

import axios from 'axios';
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
 // Import component
    import Loading from 'vue3-loading-overlay';
    import Swal from 'sweetalert2';
    import { notify } from "@kyvg/vue3-notification";


    // Import stylesheet





export default {
  name: "Login",
   components: {             
     Loading,
 },



  
  data() {
    return {
      myModel: false,
      username: '',
      password: '',
      error: '',
      isLoading: false,
       loading: false,
      color: "black",
      size: "10",
      sent : false,
      t:{},
      error_mail : '',
      mail : "",
      activity:{},
      msg_succes:'',
    }
  },
  methods: {
    open(){
      this.myModel = true;
      console.log(this.myModel);
    },
    notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },
        addActivity(id, link){
      this.activity.user_id= id;
      this.activity.action= "logged in";
      this.activity.model = "User"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = id;
            console.log("add activity")

      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log("yes")
          console.log(res.data);
        })
        .catch(error => {
          console.log("no")
            console.log(error)
           
        }) 

      },
    handlesubmit(){
      this.isLoading = true;
      this.error = "";
       this.error_mail = "";
       this.msg_succes = "";
     // console.log("Clicked!");
      // console.log(this.lien+'/login');
      this.$store.dispatch('performLogin', {//data to be sended
        nameRoute : '/api/loginsec',
        email: this.email,
        password : this.password
      })
      .then (res => {
        this.isLoading = false
        //if(this.$store.getters.get_Hote == )
        if(!this.$store.getters.get_loggedIn) this.error = "Sorry! you are not authorized to access this page!"
        if(this.$store.getters.get_loggedIn) {this.$router.push('/d');  var user = this.$store.getters.get_user ; this.addActivity( user.id, '/api/loginsec');
         }
        

      })
      .catch (err => {
        this.isLoading = false;
       // this.notificationError();
        if(!err.response) this.error = "Probleme de connexion, Verifiez le réseau internet";
        else{
          if(JSON.stringify(err.response.status) == 401) this.error = err.response.data.error;
          else if(err.response.data.message == "IP address banned. Too many login attempts.") this.error = "Votre compte a été limité, Veuillez reassayer dans une heure"
          else this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"

        
        }
       

        

      })
      
    
    },
    confirmed(){
       Swal.fire({
    icon: 'success',
    title: "Envoyé",
    showConfirmButton: false,
    timer: 2000
    })

    },
      envoyer(){
        console.log("***********envoyer")

      this.sent = true;
      this.error_mail = "";
      this.error = "";
      this.msg_succes = "";
      this.t.blade = "mail_change_password";
      this.t.email = this.mail;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/rest',
      t : this.t
      })    
     .then(res => {
       console.log("***********oui")
       this.sent = false;
       this.myModel = false;
       console.log(res.data);
       this.msg_succes= "Votre mot de pass a été reintialisé avec succés, Vérifiez votre boite email";
      // this.confirmed();
     })
     .catch(err => {
       console.log("***********error")
       console.log(err)
       this.sent = false;
       console.log(err.response)
              if(!err.response) this.error_mail = "Probleme de connexion, Verifiez le réseau internet";
              else{
                 if(JSON.stringify(err.response.status) == 401) this.error_mail= err.response.data.error;
                else if (err.response.data.message) this.error_mail= err.response.data.message;
                else this.error_mail= "Une erreur s'est produite lors de la reintialisation du mot de passe, Veuillez resseayer plus tard, si ça persiste, contacter l'administrateur";
              
              }
               
              

         
     })

    },
    close(){
      this.myModel = false;
      this.sent = false;
      this.error_mail = "";
      this.t.email = "";
      this.mail = "";

    }

    
  }

}
</script>

<style>
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }
   #inputLogin:hover{
     background: #B90000 !important;

   }

</style>